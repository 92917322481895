import Vue from "vue";
import Vuex from "vuex";
import axios from "@/axios/http";
import modules from "./modules";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  //modules,
  state: {
    username: null,
    title: "",
    loading: false,
    error: null,
    rights: [],
    lists: {
      statusList: [
        { id: "U", text: "未處理", style: "red" },
        { id: "R", text: "已收件", style: "orange" },
        { id: "W", text: "等收款", style: "yellow" },
        { id: "P", text: "已收款", style: "green" },
        { id: "N", text: "未付已派", style: "indigo" },
        { id: "S", text: "已派貨", style: "blue" },
        { id: "C", text: "已取貨", style: "purple" },
        { id: "V", text: "已取消", style: "grey" },
      ],
      payStatusList: [
        { id: "U", text: "未收款", style: "red" },
        { id: "W", text: "等收款", style: "yellow" },
        { id: "P", text: "已收款", style: "green" },
      ],
      recStatusList: [
        { id: "A", text: "Active" },
        { id: "C", text: "Cancelled" },
      ],
      roleList: [
        { id: "A", text: "Admin" },
        { id: "S", text: "Shop" },
      ],
    },
    maps: {
      recStatusMap: {
        A: "Active",
        C: "Cancelled",
      },
    },
  },
  mutations: {
    setState(state, { data, stateName }) {
      const s = stateName.split(".");
      s.length == 0 ? (state[stateName] = data) : (state[s[0]][s[1]] = data);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload.message;
      //console.log('state.error='+ state.error)
    },
    clearError(state) {
      state.error = null;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    signUserIn(state, payload) {
      const { token, locationId, role } = payload;
      localStorage.setItem("token", token);
      localStorage.setItem("locationId", locationId);
      localStorage.setItem("role", role);
      // localStorage.setItem("refreshToken", refreshToken);
      // localStorage.setItem("staffBranchs", JSON.stringify(branches));
      // localStorage.setItem("selectedBranch", JSON.stringify([branchId]));
    },
    signAnonymousIn(state, payload) {
      const { token } = payload;
      localStorage.setItem("anonymousToken", token);
      localStorage.setItem("locationId", 0);
      if (!localStorage.role) {
        localStorage.setItem("role", "");
      }
      // localStorage.setItem("refreshToken", refreshToken);
      // localStorage.setItem("staffBranchs", JSON.stringify(branches));
      // localStorage.setItem("selectedBranch", JSON.stringify([branchId]));
    },
    signUserOff(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("locationId");
      localStorage.removeItem("role");
      // localStorage.removeItem("refreshToken");
      // localStorage.removeItem("staffBranchs");
      // localStorage.removeItem("selectedBranch");
      state.username = null;
      // state.branch.branches = [];
    },
    setUserRight(state, payload) {
      //state.rights = payload.rights;
      state.rights = payload.rights;
    },
  },
  actions: {
    callApi({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const {
            url,
            data = {},
            mutation = null,
            stateName = null,
            method = "get",
          } = payload;
          const response = await axios({
            method,
            url,
            data,
          });
          mutation
            ? await commit(mutation, response.data)
            : stateName &&
              (await commit("setState", { data: response.data, stateName }));
          resolve(true);
        } catch (e) {
          if (e.response.status == 401) {
            await commit("signUserOff");
            router.push("/login");
          }
          reject(e);
        }
      });
    },
    signUserIn({ commit }, payload) {
      console.log("signUserIn", new Date());
      //console.log(payload);
      // console.log('1. signUserIn')
      return new Promise((resolve, reject) => {
        //axios.post('http://192.168.5.25:8080/limo/auth',payload)
        //console.log(payload)
        axios
          .post("/public/login", payload)
          .then((response) => {
            // console.log('2. Promise resolve');
            console.log({ response });
            commit("signUserIn", response.data);
            commit("setUsername", payload.username);
            resolve(true);
          })
          .catch((error) => {
            // console.log('2 Promise reject');
            console.log({ error });
            commit("signUserOff");
            commit("setLoading", false);
            commit("setError", error);
            reject(error);
          });
      });
    },
    signUserOff({ commit }) {
      commit("signUserOff");
    },
    clearError({ commit }) {
      commit("clearError");
    },
    getUserRight({ commit }) {
      console.log("getUserRight", new Date());
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/user")
          .then((response) => {
            commit("setUserRight", response.data);
            commit("setUsername", {
              username: response.data.username,
              fullname: response.data.fullname,
              userid: response.data.userid,
              branchId: response.data.branchId,
              clientGroupId: response.data.clientGroupId,
              branches: response.data.branches,
            });
            resolve(true);
          })
          .catch((error) => {
            commit("signUserOff");
            reject(error);
          });
      });
    },
    getAnonymousToken({ commit }) {
      return new Promise((resolve, reject) => {
        //axios.post('http://192.168.5.25:8080/limo/auth',payload)
        //console.log(payload)
        axios
          .get("/public/anonymousToken")
          .then((response) => {
            // console.log('2. Promise resolve');
            // console.log({ response });
            commit("signAnonymousIn", response.data);
            resolve(true);
          })
          .catch((error) => {
            // console.log('2 Promise reject');
            console.log({ error });
            commit("signUserOff");
            commit("setLoading", false);
            commit("setError", error);
            reject(error);
          });
      });
    },
  },
  getters: {
    username(state) {
      return state.username;
    },
    loading(state) {
      return state.loading;
    },
    error(state) {
      return state.error;
    },
    rights(state) {
      return state.rights;
    },
    getStatusList(state) {
      return state.lists.statusList;
    },
    getStatusMap(state) {
      return state.lists.statusList.reduce((a, b) => {
        return { ...a, [b.id]: b.text };
      }, {});
    },
    getPayStatusList(state) {
      return state.lists.payStatusList;
    },
    getRecStatusList(state) {
      return state.lists.recStatusList;
    },
    getRecStatusMap(state) {
      return state.maps.recStatusMap;
    },
    getRoleList(state) {
      return state.lists.roleList;
    },
    getRoleMap(state) {
      return state.lists.roleList.reduce((a, b) => {
        return { ...a, [b.id]: b.text };
      }, {});
    },
    getStyleMap(state) {
      return state.lists.statusList.reduce((a, b) => {
        return { ...a, [b.id]: b.style };
      }, {});
    },
  },
});
