<template>
  <v-app id="app">
    <v-navigation-drawer disable-resize-watcher v-model="drawer" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-item
            :key="item.title"
            :to="item.link"
            v-if="hasRight(item.right)"
          >
            <v-list-item-action>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dense class="blue-grey darken-1" dark v-if="toolbar">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        :disabled="pageTitle == 'Login'"
      />
      <b class="mytitle">{{ title }}</b>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view
          class="pa-2"
          :closeMenu="closeMenu"
          :isMenuOpen="drawer"
          :hideToolbar="hideToolbar"
        />
      </v-container>
    </v-main>
    <!--
    <v-footer color="primary" app>
      <span class="white--text"
        >Copyright © 2020 Harvest Business Solutions. All right reserved.</span
      >
    </v-footer>
		-->
  </v-app>
</template>

<script>
export default {
  data: () => ({
    title: "HABIZO 系統排列方案",
    items: [
      {
        action: "mdi-shopping",
        title: "HABIZO",
        link: "/board",
        right: ["A"],
      },
      {
        action: "mdi-exit-to-app",
        title: "Logout",
        link: "/logout",
        right: ["A"],
      },
    ],
    toolbar: true,
    drawer: false,
    lock: false,
  }),
  watch: {
    pageTitle(val) {
      if (val) this.title = val;
    },
  },
  computed: {
    pageTitle() {
      return this.$route.meta.title;
    },
  },
  methods: {
    hasRight(val) {
      return true;
      //return val.indexOf(localStorage.role) > -1;
    },
    closeMenu(val) {
      this.drawer = !val;
    },
    hideToolbar(val) {
      this.toolbar = val;
    },
    home() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.mytitle {
  font-size: 1rem;
}
</style>
