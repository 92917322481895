import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { title: "Login", anomymous: true },
    component: Login,
  },
  {
    path: "/board",
    name: "Board",
    meta: { title: "HABIZO ｜ 系 統 排 列 方 案" },
    component: () =>
      import(/* webpackChunkName: "views" */ "../views/Board.vue"),
  },
  {
    path: "/logout",
    meta: {
      title: "Logout",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("signUserOff");
      next("/login");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //console.log({ to });
  if (!to.meta.anomymous) {
    if (to.meta.title == "Logout") {
      next();
    } else if (localStorage.token) {
      if (to.path == "/") {
        next("/board");
      } else {
        next();
      }
    } else {
      next("/login");
    }
    //} else if (to.meta.title == "Login") {
    //  next();
  } else {
    next();
  }
});

export default router;
