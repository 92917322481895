/**
 * http config
 */

import axios from "axios";

const allowedRoutes = [
  { method: "GET", url: "/private/areas" },
  { method: "GET", url: "/private/locations" },
  { method: "POST", url: "/private/orders" },
  { method: "POST", url: "/private/getOrderByNo" },
  { method: "POST", url: "/uploadFile" },
  { method: "GET", url: "/public/anonymousToken" },
  { method: "GET", url: "/public/checkToken" },
  { method: "POST", url: "/private/updateStatus" },
];

// import createAuthRefreshInterceptor from "axios-auth-refresh";
// import uuidv4 from "uuid/v4";

// axios config
axios.defaults.timeout = 60000;
//console.log(window.origin.includes("192.168.5.11"));
if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "";
}

/*
// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest =>
  axios
    .post("/auth/refresh", { refreshToken: `${localStorage.refreshToken}` })
    .then(tokenRefreshResponse => {
      localStorage.setItem("token", tokenRefreshResponse.data.token);
      // failedRequest.response.config.headers["Authentication"] = "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic);
*/
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    /*
    const { url, method } = config;
    const anonymousAccess = allowedRoutes.find(
      (x) => method.toUpperCase() == x.method && url.includes(x.url)
    )
      ? true
      : false;
    */
    /*
    console.log({ url });
    console.log({ method });
    console.log({ anonymousAccess });
    */
    return {
      ...config,
      /*
    baseURL:
      process.env.NODE_ENV == "production"
        ? config.url.includes("langs")
          ? window.origin.includes("192.168.5.11")
            ? process.env.VUE_APP_BASE_API_LANGS_NODE_INTERNAL
            : process.env.VUE_APP_BASE_API_LANGS_NODE
          : config.url.includes("/node")
          ? window.origin.includes("192.168.5.11")
            ? process.env.VUE_APP_BASE_API_NODE_INTERNAL
            : process.env.VUE_APP_BASE_API_NODE
          : window.origin.includes("192.168.5.11")
          ? process.env.VUE_APP_BASE_API_INTERNAL
          : process.env.VUE_APP_BASE_API
        : "",
    url:
      process.env.NODE_ENV == "production"
        ? config.url.includes("langs")
          ? "/limo/api/chinaLink/langs"
          : config.url.includes("/node")
          ? config.url.replace("/node", "/limo")
          : `/limo${config.url}`
        : config.url,
    */
      baseURL: process.env.VUE_APP_BASE_API,
      url: config.url,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${
          localStorage.token ? localStorage.token : localStorage.anonymousToken
        }`,
      },
    };
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axios;
