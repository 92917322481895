<template>
  <v-main>
    <m-dialog @close="mDialogItem.message = null" :item="mDialogItem" />
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" align="center" justify="center">
          <v-card class="elevation-12">
            <!--<v-img :src="require('../assets/kingkong.jpg')" max-height="300" max-width="300" />-->
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Login"
                  name="login"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="p.username"
                ></v-text-field>

                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="p.password"
                  @keyup.enter="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue white--text ma-3" @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["closeMenu", "isMenuOpen"],
  data() {
    return {
      p: {
        username: null,
        password: null,
      },
      mDialogItem: { message: null },
    };
  },
  mounted() {
    this.closeMenu(true);
  },
  methods: {
    ...mapActions(["signUserIn"]),
    async login() {
      const payload = Object.assign({}, this.p);
      try {
        const res = true;
        localStorage.setItem("token", "123");
        //const res = await this.signUserIn(payload);
        if (res) {
          this.$router.replace("/board");
        }
      } catch (err) {
        this.mDialogItem = {
          alertType: "error",
          message: `Login problem (${
            err.response ? err.response.data.message : "Unknown"
          })`,
        };
      }
    },
  },
};
</script>
