<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
    @keydown.enter="dialog = false"
  >
    <v-card :class="alertType">
      <v-card-title class="white--text">
        {{ message }}
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      message: null,
      alertType: null,
      maxWidth: "500px",
    };
  },
  props: ["item"],
  watch: {
    item: {
      handler(val) {
        //console.log(val)
        if (val.message) {
          this.dialog = true;
          this.message = val.message;
          this.maxWidth = val.maxWidth || this.maxWidth;
          this.alertType = val.alertType || null;
        }
      },
      deep: true,
    },
    //Close Dialog
    dialog(val) {
      if (!val) {
        this.message = null;
        this.maxWidth = "500px";
        this.$emit("close");
      }
    },
  },
  methods: {},
};
</script>
