import Vue from 'vue'
import App from './App.vue'
import VueKonva from 'vue-konva'
import vuetify from './plugins/vuetify';
import router from "./router";
import store from "./store";
import MDialog from "./components/MDialog.vue";
import CDialog from "./components/CDialog.vue";

Vue.use(VueKonva)
Vue.component("m-dialog", MDialog);
Vue.component("c-dialog", CDialog);
Vue.config.productionTip = false

new Vue({
	router,
	store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
