<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline"> {{ header }} </v-card-title>
      <v-textarea
        v-model="message"
        class="ma-3"
        rows="10"
        no-resize
        outlined
        readonly
      ></v-textarea>
      <v-card-actions>
        <v-layout row>
          <v-spacer />
          <v-btn class="blue white--text mr-3" @click.native="dialog = false">{{
            btnTextNo
          }}</v-btn>
          <v-btn class="blue white--text mr-3" @click.native="onConfirm">{{
            btnTextYes
          }}</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      header: null,
      message: null,
      alertType: null,
      btnTextYes: "Yes",
      btnTextNo: "No",
      isConfirm: false,
      dialogName: null,
    };
  },
  props: ["item"],
  watch: {
    item: {
      handler(val) {
        if (val.message) {
          this.dialog = true;
          this.header = val.header;
          this.message = val.message;
          this.btnTextYes = val.btnTextYes;
          this.btnTextNo = val.btnTextNo;
          this.maxWidth = val.maxWidth || this.maxWidth;
          this.alertType = val.alertType || null;
          this.dialogName = val.dialogName;
        }
      },
      deep: true,
    },
    //Close Dialog
    dialog(val) {
      if (!val) {
        this.message = null;
        this.$emit("close", {
          dialogName: this.dialogName,
          isConfirm: this.isConfirm,
        });
        this.isConfirm = false;
      }
    },
  },
  methods: {
    onConfirm() {
      this.isConfirm = true;
      this.dialog = false;
    },
  },
};
</script>
