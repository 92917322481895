const state = {
  users: [],
  user: "",
};
const url = "/private/users",
  m = { o: "setUser", s: "setUsers" };

const actions = {
  getUsers({ dispatch }) {
    return dispatch("callApi", { url: `${url}`, mutation: m.s });
  },
  getUser({ dispatch }, payload) {
    return dispatch("callApi", { url: `${url}/${payload}`, mutation: m.o });
  },
  patchUser({ dispatch }, { id, data }) {
    return dispatch("callApi", {
      url: `${url}/${id}`,
      method: "patch",
      data: data,
      mutation: m.o,
    });
  },
  postUser({ dispatch }, { data }) {
    return dispatch("callApi", {
      url: `${url}`,
      method: "post",
      data,
      mutation: m.o,
    });
  },
  deleteUser({ dispatch }, { id }) {
    return dispatch("callApi", {
      url: `${url}/${id}`,
      method: "delete",
    });
  },
  changePassword({ dispatch }, { data }) {
    return dispatch("callApi", {
      url: `/private/changePassword`,
      method: "post",
      data,
    });
  },
};

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
};

const getters = {
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  },
  activeUsers(state) {
    return state.users.filter((x) => x.status == "A");
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
