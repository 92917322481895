const requireModule = require.context(".", false, /\.js$/); //extract js files inside modules folder
// const modules = {};

export default require
  .context(".", false, /\.js$/)
  .keys()
  .reduce(
    (a, x) =>
      x != "./index.js"
        ? {
            ...a,
            [x.replace(/(\.\/|\.js)/g, "")]: requireModule(x).default,
          }
        : a,
    {}
  );
